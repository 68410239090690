/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "Font Awesome 5 Brands Regular";
    src: url("assets/fonts/fa-brands-400.woff");
}

@font-face {
    font-family: "Font Awesome 5 Free Regular";
    src: url("assets/fonts/fa-regular-400.woff");
}

@font-face {
    font-family: "Font Awesome 5 Free Solid";
    src: url("assets/fonts/fa-solid-900.woff");
}


.ng-valid[required],
.ng-valid.required {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


/* .ng-invalid:not(form) {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
} */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

mtop-10 {
    margin-top: 10rem;
}

#sticky-footer {
    flex-shrink: none;
}


/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
    display: block !important;
    margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
}

.iti__flag-container.open+input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 479px) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

#hero {
    width: 100%;
    height: 90vh;
    background: url("assets/images/hero_mobile.jpg") center / cover;
}

@media (min-width: 768px) {
    #hero {
        background: url("assets/images/hero_display.jpg") center / cover;
        width: 100%;
        height: 90vh;
    }
}

body {
    font-size: 16px;
    font-family: Montserrat, sans-serif;
}

.dropdown-item hover,
.dropdown-item:hover {
    font-weight: 500;
    color: #688c39 !important;
}

#search .form-control {
    border-radius: 0;
    padding: 0;
}

.input-group {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    text-align: center;
}

.input-group search {
    border-radius: 0;
}

#search .container {
    width: 500px;
    text-align: center;
}

#search .container {
    position: relative;
    text-align: center;
    max-width: 200px;
}

h1 {
    font-size: 25px;
    color: white;
    text-align: center;
    font-family: 'Lexend Giga', sans-serif;
    line-height: 35px;
    padding-bottom: 10px;
    margin-bottom: 0;
}

.container hero {
    margin: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

h2 {
    font-size: 18px;
    font-family: Montserrat, sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-style: normal;
    text-align: center;
    line-height: 25px;
    margin-bottom: 40px;
    margin-top: 10px;
}

h3 {
    color: rgb(47, 47, 47);
    font-size: 23px;
    font-family: 'Lexend Deca', sans-serif;
    text-align: center;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

.container.register a {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 15px;
    text-decoration: underline;
}

#anleitung .container {
    text-align: center;
}

h5 {
    font-size: 18px !important;
    margin-top: 30px !important;
    font-weight: bold !important;
}

p {
    margin-top: 15px;
    Font-size: 16px;
}

#anleitung .col-md-4 {
    margin-top: 50px;
}

#anleitung #topdrei {
    margin-top: 30px;
}

#topdrei {
    margin-top: 70px;
    padding: 50px 0px 80px 0px;
    text-align: center;
    color: rgb(0, 0, 0);
    background: #ffe3d5;
}

#anleitung {
    margin-top: 32px;
}

#topdrei .col-md-4 {
    margin-top: 40px;
}

.herotext {
    height: 50vh;
    /*margin-top: 50px;*/
}

.container.hero {
    margin-top: 20px;
}

.container.register {
    text-align: center;
    margin-top: 5rem;
}

.mt-5,
.my-5 {
    margin-top: 5rem!important;
}

.btn.btn-primary {
    color: rgb(255, 255, 255);
    background: #94c357;
}

.btn {
    background: #94c357 !important;
    color: #ffffff !important;
    border-width: 0px !important;
    border-radius: 0px!important;
    padding: 30px!important;
    padding-top: 6px!important;
    padding-right: 12px!important;
    padding-bottom: 6px!important;
    padding-left: 12px!important;
    box-shadow: inset!important;
}

.btn:hover {
    color: rgb(255, 255, 255);
    background: rgb(119, 165, 59);
    border-style: none;
    border-color: rgba(119, 165, 59, 0);
}

.col-10 {
    padding: 0px;
}

.col {
    padding: 0px;
}

input {
    height: 100%;
    border-color: rgba(0, 0, 0, 0);
    width: 100%;
}

.tours {
    flex-flow: row wrap;
    align-content: center;
    align-items: stretch;
    justify-content: center;
    /*margin: 0.2px;*/
}

.tours>.tourcard {
    /*-webkit-transition: all .3s ease-in-out;*/
    /*-moz-transition: all .3s ease-in-out;*/
    /*-o-transition: all .3s ease-in-out;*/
    /*transition: all .3s ease-in-out;*/
    background: #94c357;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    flex: 0 1 420px;
    margin: px;
    padding-bottom: 15px;
}

.tours>.tourcard figure {
    margin: 0;
    padding: 0;
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    width: 100%;
}

.tourpic {
    background: #444;
    position: relative;
    margin-bottom: 20px;
    height: 180px;
    width: 100%;
    text-align: center;
}

.tourbtn {
    color: #F6AF8C !important;
    margin: 0;
    padding: 0px 20px 0px 20px;
}

.tourbtn:after {
    clear: both;
}

.tours>.tourcard figure {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    /*opacity: .50;*/
}

.tours>.tourcard figure .tourpic .tourcat {
    color: #fff;
    font-size: 1.2em;
    padding: 3px 5px;
    position: absolute;
    right: 15px;
    bottom: 10px;
}

.tours>.tourcard figure .tourpic .tourday {
    font-family: 'Lexend Deca';
    letter-spacing: 2px;
    color: #fff;
    font-size: .9em;
    position: absolute;
    right: 15px;
    top: -3px;
    padding: 3px 10px;
    border-radius: 0px 0px 5px 5px;
}

.tours>.tourcard figure .tourpic .tourday.hot {
    background: #000;
    background: rgb(246, 175, 140);
    background-size: 200% 200%;
}

.tourbtn .btn:hover {
    font-family: 'Lexend Deca';
    font-weight: normal;
    /*font-size: 18px;*/
    letter-spacing: 1px;
    background-color: #F18955;
    /*margin-top: 30px;*/
    /*margin-bottom: 10px;*/
    /*padding: 8px 15px 8px 15px;*/
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.tourbtn .btn {
    font-family: 'Lexend Deca';
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #F6AF8C !important;
    margin-top: 20px;
    margin-bottom: 7px;
    /*padding: 8px 21px 8px 21px;*/
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    text-transform: uppercase;
}

.restaurant {
    text-align: left !important;
    color: white;
}

.maps a {
    color: white;
}

.maps {
    font-size: 0.9rem;
    margin-top: 20px;
    font-weight: 500;
    /*text-decoration: underline;*/
}

.standort {
    color: white;
    font-weight: 200;
}

.h4,
h4 {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.fas.fa-map-marker-alt {
    color: white;
    padding-right: 5px;
}

.container {
    max-width: 90%;
}

.tours>.tourcard figure .tourbtn {
    background: transparent;
    /*display: flex;*/
    width: 100%;
    flex-flow: row wrap;
    align-content: center;
}

.tourpic img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.row {
    margin: 1px;
}

#newdrei {
    background-color: #ECF4E1;
    text-align: center;
    padding-top: 50px;
    padding: 50px 0px 80px 0px;
    text-align: center;
}

#newdrei .col-md-4 {
    margin-top: 50px;
    /*margin-left: 2px;*/
    /*margin-right: 2px;*/
}

#description {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 150px;
}

#description .container {
    width: 649px;
    margin-top: 30px;
}

hr.solid {
    border-top: 4px solid;
    width: 50px;
    margin-top: 20px;
    color: #F6AF8C;
}

#description img {
    width: 50px;
    margin-bottom: 20px;
}

footer {
    background-color: #F1FAE5;
    text-align: center;
    color: white;
    padding-bottom: 10px;
    padding-top: 10px;
    align-content: center;
    margin-top: 50px;
}

.container.hero {
    margin-top: 10rem;
}

footer a {
    color: #606060 !important;
    font-size: 25px;
    /*margin-left: 20px;*/
    /*margin-right: 20px;*/
}

footer a:hover {
    color: #a0a0a0 !important;
    /*font-size: 25px;*/
}

.footertext {
    text-align: center !important;
    margin-bottom: 0 !important;
    margin-top: -1px !important;
    text-decoration: none !important;
    font-size: 10px !important;
}

.col-2.offset-4.border {
    border-right: 2px solid;
}

.navbar {
    background-color: #f1fae5;
    color: #000;
}

.navbarmain {
    background-color: transparent;
    color: #fff;
}

.top-nav-collapse {
    background-color: #f1fae5;
    color: #000000;
}


/* @media only screen and (max-width: 768px) {
    .navbar {
        background-color: #f6af8c;
    }
} */

.login-clean {
    background: #f1f7fc;
    padding: 80px 0;
}

.login-clean form {
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 4px;
    color: #505e6c;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.login-clean .illustration {
    text-align: center;
    padding: 0 0 20px;
    font-size: 100px;
    color: rgb(244, 71, 107);
}

.login-clean form .form-control {
    background: #f7f9fc;
    border: none;
    border-bottom: 1px solid #dfe7f1;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    text-indent: 8px;
    height: 42px;
}

.login-clean form .btn-primary {
    background: #f4476b;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none !important;
}

.login-clean form .btn-primary:hover,
.login-clean form .btn-primary:active {
    background: #eb3b60;
}

.login-clean form .btn-primary:active {
    transform: translateY(1px);
}

.login-clean form .forgot {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #6f7a85;
    opacity: 0.9;
    text-decoration: none;
}

.login-clean form .forgot:hover,
.login-clean form .forgot:active {
    opacity: 1;
    text-decoration: none;
}

form {}

.book {
    border-radius: 0px;
    border-width: 1px;
    border-color: #808080;
    padding: 10px;
}

#bookingform {
    margin-top: 50px;
    margin-bottom: 50px;
}

#restaurant {
    text-align: center;
    margin-bottom: 40px;
}

.form-control.is-valid {}

.headbook {
    background-color: #ffe3d5;
    height: auto;
    margin-bottom: 50px;
    padding-top: 20px;
    padding-bottom: px;
}

.row.register {
    background-color: #ECF4E1;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

small {
    font-size: 11.8px;
}

.Hinweis {
    font-size: 15px;
    text-align: center;
    margin-bottom: 40px;
}

.custom-control.custom-checkbox.register {
    text-align: center;
}

#register {
    margin-bottom: 20px;
    margin-top: 50px;
    padding-top: 50px;
}

.reservationid {
    position: absolute;
    background: rgb(246, 175, 140);
    font-size: 14px;
    padding: 5px 10px 5px 10px;
    left: 15px;
    top: -10px;
    border-radius: 0px 0px 5px 5px;
    font-family: 'Lexend Deca';
}

.row.reservationcard {
    background: #ECF4E1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.col-md-12.resID {
    padding: 20px;
}

.container.reservationcard {
    background: #ECF4E1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    padding-bottom: 20px;
    max-width: 800px;
}

.btn.btn-primary.col-md-12.ablehnen:hover {
    background-color: #F18955;
    margin-bottom: 10px;
}

.btn.btn-primary.col-md-12.ablehnen {
    background-color: #F6AF8C;
    margin-bottom: 10px;
}

h6 {
    font-size: 12px;
    font-family: 'Montserrat';
    margin-bottom: -10px;
}

.dates {
    font-weight: 600;
    font-family: 'Lexend Deca';
}

.col-md-4 {
    padding: 0px;
}

.row.reserv {
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.container.full {
    max-width: 100%;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 200px;
}

.row.full {
    max-width: 100%;
    padding: 0px;
    height: 200px;
}

.col-md-12 {
    padding: 0px;
    height: 50px;
}

.col-md-12 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.col.col-md-8.data {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    background-color: #ecf4e1;
    padding: 20px 20px 30px 20px;
}

.col.col-md-12.data {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    background-color: #ecf4e1;
    padding: 20px 20px 30px 20px;
}

.row.one {
    margin-top: 20px;
}

label {
    font-size: 12px;
    margin-bottom: 2px;
}

.col-md-6 {
    padding: 0px 5px 0px 0px;
}

.form-control.book {
    font-size: 12px;
}

.col-md-12.headprofile {
    height: 200px;
}

.btn.btn-primary.reservation.save {
    margin-top: 20px;
}

.btn.btn-primary.data {
    margin-top: 20px;
}

.pic {
    width: 100%;
    object-fit: cover;
}

.btn.btn-primary.rosa:hover {
    background-color: #F18955;
    text-align: center;
}

.btn.btn-primary.rosa:active {
    background-color: #F6AF8C;
    text-align: center;
}

.btn.btn-primary.rosa {
    background-color: #F6AF8C;
    text-align: center;
}

.pic.col-md-12 {
    height: 200px;
    margin-bottom: 10px;
    object-fit: cover;
}

h6 {
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: 30px;
}

.col-md-6.datei {
    font-size: 12px;
}

.col.col-md-8.data.end {
    margin-bottom: 50px;
}

.icon {
    height: 40px !important;
    width: 40px !important;
    display: block;
    /* Other styles here */
}